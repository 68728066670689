<template>
  <Splide
    :options="options"
    :extensions="extensions"
    id="carousel-container"
    @splide:move="stopVideoOnSwipe()"
  >
    <SplideSlide
      v-for="profile in videoProfiles"
      :key="profile.id"
      :data-splide-vimeo="profile.videoFull"
      class="carousel__item"
      @click="selected = true"
    >
      <img :src="profile.poster" loading="lazy" :alt="profile.name" />
      <div
        v-if="selected != true"
        class="video-caption"
        :class="profile.name.replace(/\s+/g, '')"
      >
        <h3>{{ profile.name }}</h3>
        <p>{{ profile.description }}</p>
        <!-- <transition name="fade">
            <button v-if="profile.hover">Full Video</button></transition
          > -->
      </div>
    </SplideSlide>
  </Splide>
</template>

<script>
import { Splide, SplideSlide } from "@splidejs/vue-splide";
import { Video } from "@splidejs/splide-extension-video";
import { defineComponent } from "vue";
import "@splidejs/splide/dist/css/themes/splide-default.min.css";

import "@splidejs/splide-extension-video/dist/css/splide-extension-video.min.css";

export default defineComponent({
  name: "VideoExample",

  components: {
    Splide,
    SplideSlide,
  },

  setup() {
    const options = {
      rewind: true,
      //   disablePictureInPicture: true,
      //   disableOverlayUI: true,

      //   heightRatio: 0.5625,
    };

    return {
      options,
      extensions: { Video },
    };
  },
  data() {
    return {
      selected: undefined,

      videoProfiles: [
        {
          id: 1,
          hover: true,
          buttonHover: false,
          fullVideo: false,
          firstVideo: true,
          caption: "https://tcnj.edu/custom/homepage/captions/sebastian-1.vtt",

          poster:
            "https://tcnj.edu/custom/homepage/img/rollover-stills/9-15-23/jeb-adjusted.jpg",
          name: "Sebastian Sanchez",
          description:
            "As a transfer student, Sebastian had an easy transition to TCNJ thanks to supportive faculty and new friends he met at orientation.",
          // videoClip: "https://assets.codepen.io/3964360/vid-1.mov",
          videoFull:
            // "https://player.vimeo.com/progressive_redirect/playback/684683245/rendition/1080p?loc=external&signature=10d0cf847d829bbb0498991d9fc423799f3a55ee826b1b3700fe6369223c8a72",
            "https://vimeo.com/864899656",
        },
        {
          id: 2,
          hover: false,
          buttonHover: false,
          fullVideo: false,
          poster:
            "https://tcnj.edu/custom/homepage/img/rollover-stills/9-15-23/diamond-adjusted.jpg",
          name: "Diamond Urey",
          caption: "https://tcnj.edu/custom/homepage/captions/diamond-1.vtt",
          description:
            "It’s Diamond’s job to make sure her fellow Lions feel at home at TCNJ.",
          // videoClip: "https://assets.codepen.io/3964360/vid-3.mov",
          videoFull:
            // "https://player.vimeo.com/progressive_redirect/playback/684683480/rendition/1080p?loc=external&signature=bc8aac8c833b45f7290bd5c9bd262c721d5e0497998e2a98318316e82f7093e3",
            "https://vimeo.com/864899505",
        },
        {
          id: 3,
          hover: false,
          buttonHover: false,
          fullVideo: false,
          poster:
            "https://tcnj.edu/custom/homepage/img/rollover-stills/9-15-23/jax-adjusted.jpg",
          name: "Jax DiEugenio",
          caption: "https://tcnj.edu/custom/homepage/captions/jax-2.vtt",

          description:
            "Jax is doubling his ROI, earning two degrees in five years through an accelerated program.",
          // videoClip: "https://assets.codepen.io/3964360/vid-2.mov",
          videoFull:
            // "https://player.vimeo.com/progressive_redirect/playback/684682705/rendition/1080p?loc=external&signature=39fd7623f503a360304d6ae94505c028af7553a22e47bc6c5c683d16b3cf5618",
            "https://vimeo.com/865599256",
        },
      ],
    };
  },
  methods: {
    stopVideoOnSwipe() {
      console.log("swipe");
      // videoProfiles.fullVideo = false;
      this.selected = undefined;
      // profile.fullVideo = false;

      // profile.fullVideo = false;
    },
  },
});
</script>
