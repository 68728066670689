<template class="full-width" id="split-yellow">
  <div class="inner" id="video-grid">
    <div
      v-for="profile in videoProfiles"
      :key="profile.id"
      class="video-container"
      :class="{ active: profile.hover }"
    >
      <div class="hoverPlayButton" v-if="profile.id != selected"></div>
      <span
        id="close-video"
        v-if="profile.fullVideo && profile.id == selected"
        @click="
          fullVideoClose(profile);
          profile.hover = false;
          toggleCaptionButton = false;
          selected = undefined;
        "
        ><img
          style="max-width: 22px"
          src="https://tcnj.edu/custom/homepage/img/video-close-icon.svg"
          alt="X"
      /></span>
      <div id="ccContainer" v-if="profile.fullVideo && profile.id == selected">
        <img
          v-if="!toggleCaptionButton"
          @click="
            captionsToggleOn();
            toggleCaptionButton = true;
          "
          src="https://tcnj.edu/custom/homepage/img/cc-button-off.svg"
          alt="CC"
          style="max-width: 48px"
        />

        <img
          v-else
          @click="
            captionsToggleOff();
            toggleCaptionButton = false;
          "
          src="https://tcnj.edu/custom/homepage/img/cc-button-on.svg"
          alt="CC off"
          style="max-width: 48px"
        />
      </div>
      <!-- <div class="gradient-overlay"></div> -->
      <div
        class="inner-container"
        @click="
          fullVideoClick(profile, videoProfiles);
          selected = profile.id;
          profile.hover = false;
          toggleCaptionButton = false;
        "
      >
        <div class="video-caption" :class="profile.name.replace(/\s+/g, '')">
          <h3 v-if="profile.id != selected">{{ profile.name }}</h3>
          <p v-if="profile.id != selected">{{ profile.description }}</p>
          <!-- <transition name="fade">
            <button v-if="profile.hover">Full Video</button></transition
          > -->
        </div>
        <div v-if="!profile.fullVideo">
          <!-- <video v-if="!hover" :src="videoClip" muted="" loop=""></video>
        <video v-else :src="videoClip" muted="" loop="" autoplay></video> -->
          <video
            :class="{ firstVideo: profile.firstVideo }"
            onmouseover="this.play()"
            onmouseout="this.load()"
            :src="profile.videoClip"
            :id="profile.id"
            muted=""
            loop=""
            :poster="profile.poster"
            :autoplay="profile.hover ? true : false"
            @mouseover="
              profile.hover = true;
              stopFirst = true;
              profile.firstVideo = false;
            "
            @mouseover.once="pauseAllVideos()"
            @mouseleave="profile.hover = false"
          ></video>
        </div>
        <div v-else-if="profile.fullVideo && profile.id == selected">
          <video :src="profile.videoFull" autoplay class="full-video-play">
            <track
              preload
              kind="captions"
              label="Video Captions"
              srclang="en"
              :src="profile.caption"
            />
          </video>
        </div>
        <div v-else>
          <!-- <video v-if="!hover" :src="videoClip" muted="" loop=""></video>
        <video v-else :src="videoClip" muted="" loop="" autoplay></video> -->
          <video
            onmouseover="this.play();"
            onmouseout="this.pause();"
            :src="profile.videoClip"
            muted=""
            loop=""
            :poster="profile.poster"
            :autoplay="profile.hover ? true : false"
            @mouseover="profile.hover = true"
            @mouseleave="profile.hover = false"
          ></video>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "VideoProfileFinal",
  data() {
    return {
      selected: undefined,
      stopFirst: false,
      toggleCaptionButton: false,
      //   hover: false,
      //   fullVideo: false,
      //   name: "The Title",
      //   description: "Lorem ipum ipsum ipsum",
      //   videoClip: "https://assets.codepen.io/3964360/vid-1.mov",
      //   videoFull: "https://assets.codepen.io/3964360/vid-2.mov",
      // Image Stills Brightness -14
      // Contrast 10

      videoProfiles: [
        {
          id: 1,
          hover: true,
          buttonHover: true,
          fullVideo: false,
          firstVideo: true,

          // poster: "https://tcnj.edu/custom/homepage/img/e_kim-7.jpg",
          poster:
            "https://tcnj.edu/custom/homepage/img/rollover-stills/9-15-23/jeb-adjusted.jpg",
          name: "Sebastian Sanchez",
          caption: "https://tcnj.edu/custom/homepage/captions/sebastian-1.vtt",
          description:
            "As a transfer student, Sebastian had an easy transition to TCNJ thanks to supportive faculty and new friends he met at orientation.",
          // videoClip: "https://assets.codepen.io/3964360/vid-1.mov",
          videoClip:
            // "https://player.vimeo.com/progressive_redirect/playback/684683173/rendition/1080p?loc=external&signature=222aff46816c76db277d0b5b78d6c78d168d6e17ba89feb0c3ec6da9b0425d77",
            // "https://player.vimeo.com/progressive_redirect/playback/685471441/rendition/1080p?loc=external&signature=8aad0342b52a55c8476c3f39737f7c599c4b7b1a88a0ce6c0b7bf1b644013cfa",
            // "https://adamholsten.com/tcnj-videos/3-10/Kim-Rollover-1.mp4",
            // "https://player.vimeo.com/progressive_redirect/playback/686399608/rendition/720p?loc=external&signature=8d0b4f8239ab2ddb47a72623666aa838608f46365192f7284265953de4b31815",
            "https://player.vimeo.com/progressive_redirect/playback/865124699/rendition/720p/file.mp4?loc=external&signature=b914fff68f9ee2e52285f7bc5c35ea465b4ab18570df9468c7219c0ead10ca53",
          videoFull:
            // "https://player.vimeo.com/progressive_redirect/playback/684683245/rendition/1080p?loc=external&signature=10d0cf847d829bbb0498991d9fc423799f3a55ee826b1b3700fe6369223c8a72",
            // "https://player.vimeo.com/progressive_redirect/playback/685471394/rendition/1080p?loc=external&signature=c88b7fc490a9d11d229b5f0b577b97c4c08db8b168323abab9e1eb2e0121c2b6",
            // "https://adamholsten.com/tcnj-videos/3-10/Kim-Content-1.mp4",
            // "https://player.vimeo.com/progressive_redirect/playback/686399630/rendition/720p?loc=external&signature=accac33f63565bb867727a30fe2700e99f4ce81ce20abdd8a8e0a8726fa9071a",
            "https://player.vimeo.com/progressive_redirect/playback/864899656/rendition/720p/file.mp4?loc=external&signature=8bdb521900e8a123aa59a74b71bab2cba22e0769429127a1112e1283de79fc23",
        },
        {
          id: 2,
          hover: false,
          buttonHover: false,
          fullVideo: false,
          firstVideo: false,
          poster:
            "https://tcnj.edu/custom/homepage/img/rollover-stills/9-15-23/diamond-adjusted.jpg",
          // poster: "https://tcnj.edu/custom/homepage/img/gurram-still2.jpg",
          // name: "Starlin Regalado Nunez",
          name: "Diamond Urey",
          caption: "https://tcnj.edu/custom/homepage/captions/diamond-1.vtt",
          description:
            "It’s Diamond’s job to make sure her fellow Lions feel at home at TCNJ.",
          // videoClip: "https://assets.codepen.io/3964360/vid-3.mov",
          videoClip:
            // "https://player.vimeo.com/progressive_redirect/playback/684683035/rendition/1080p?loc=external&signature=37a1e8bccba808cfd9b3895400528e78114eb712f7a27771fb3d29ef1d78f11c",
            // "https://player.vimeo.com/progressive_redirect/playback/685471429/rendition/1080p?loc=external&signature=e71c0fd730d5d16cc4ceba90f245dc0cad85d22170392731e4f779e391f6d6c2",
            // "https://adamholsten.com/tcnj-videos/3-10/Gurram-Rollover-1.mp4",
            // "https://player.vimeo.com/progressive_redirect/playback/686399585/rendition/720p?loc=external&signature=6578e51917a558c1c26f34dc05b4c9edabac373f261fc8b7294f6c0b90709a21",
            // "https://player.vimeo.com/progressive_redirect/playback/720715518/rendition/720p/file.mp4?loc=external&signature=c2c332c9e52c22ef71858a6757fc6f45cb2ea40c896b73b90bee8fde46e9017b",
            "https://player.vimeo.com/progressive_redirect/playback/864946139/rendition/720p/file.mp4?loc=external&signature=c2e12d6843cd382ede259b3f5c849a86c6a098b933e0dc0542f1a21155f4809c",
          videoFull:
            // "https://player.vimeo.com/progressive_redirect/playback/684683480/rendition/1080p?loc=external&signature=bc8aac8c833b45f7290bd5c9bd262c721d5e0497998e2a98318316e82f7093e3",
            // "https://player.vimeo.com/progressive_redirect/playback/685471453/rendition/1080p?loc=external&signature=a86d565d227597b3d62cb4c31cf47421e02796204bf94fea332f2b8f07dd49d1",
            // "https://adamholsten.com/tcnj-videos/3-10/Gurram-Content-1.mp4",
            // "https://player.vimeo.com/progressive_redirect/playback/686399642/rendition/720p?loc=external&signature=4a25ace8ae1fee29a9b6463724de5fb120c2118742948ec97d4f7c160b9a3619",
            // "https://player.vimeo.com/progressive_redirect/playback/720715554/rendition/720p/file.mp4?loc=external&signature=c5427b1dafae8a6b4f0e5f19c379d8536e86dc6c1675ca958f18c1f060057be7",
            "https://player.vimeo.com/progressive_redirect/playback/864899505/rendition/720p/file.mp4?loc=external&signature=1d9d28cf383eb5884f9dd65ee105bba9c4b34a1ccf588fe0683696c65e4130e1",
        },
        {
          id: 3,
          hover: false,
          buttonHover: false,
          fullVideo: false,
          poster:
            "https://tcnj.edu/custom/homepage/img/rollover-stills/9-15-23/jax-adjusted.jpg",
          name: "Jax DiEugenio",
          caption: "https://tcnj.edu/custom/homepage/captions/jax-2.vtt",
          description:
            "Jax is doubling his ROI, earning two degrees in five years through an accelerated program.",
          // videoClip: "https://assets.codepen.io/3964360/vid-2.mov",
          videoClip:
            // "https://player.vimeo.com/progressive_redirect/playback/684683097/rendition/1080p?loc=external&signature=da51ff8a08e436358f7732d61a7d75327e20ded51827b6c5ee2dc3889df83e62",
            // "https://player.vimeo.com/progressive_redirect/playback/685471418/rendition/1080p?loc=external&signature=ad8cd85be5eddef3259de615b6338fa5306ad540975c6ee208fab2d7f6da9f79",
            // "https://adamholsten.com/tcnj-videos/3-10/Jamie-Rollover-1.mp4",
            // "https://player.vimeo.com/progressive_redirect/playback/686399618/rendition/720p?loc=external&signature=cd54ca815d08d9e4721982a9a2fa49fc2b40249a709952e989455d9aa34b2f82",
            "https://player.vimeo.com/progressive_redirect/playback/865124672/rendition/720p/file.mp4?loc=external&signature=1db128350e55091ee6933b6a7c048887c36ac1ad3d527a0c60160f98a0406ca3",
          videoFull:
            // "https://player.vimeo.com/progressive_redirect/playback/684682705/rendition/1080p?loc=external&signature=39fd7623f503a360304d6ae94505c028af7553a22e47bc6c5c683d16b3cf5618",
            // "https://player.vimeo.com/progressive_redirect/playback/685471374/rendition/1080p?loc=external&signature=dbdfbca9584de070522cfbfcace405f028886e4c7dd3118785419ae3e80532ee",
            // "https://adamholsten.com/tcnj-videos/3-10/Jamie-Content-1.mp4",
            // "https://player.vimeo.com/progressive_redirect/playback/686399567/rendition/720p?loc=external&signature=8056aa0798fd0333dced888e5fd0877453657626263581d081ed888027cd6cf5",
            "https://player.vimeo.com/progressive_redirect/playback/865599256/rendition/720p/file.mp4?loc=external&signature=398f4568636813ae69c95c04071666bdf422a3dbae6528f875a69f802994c2ba",
        },
      ],
    };
  },
  methods: {
    fullVideoClick(profile, videoProfiles) {
      videoProfiles.fullVideo = false;
      profile.fullVideo = true;
    },
    fullVideoClose(profile) {
      profile.fullVideo = false;
    },
    mouseOver() {
      this.hover = true;
    },
    mouseOut() {
      this.hover = false;
    },
    pauseAllVideos() {
      document.querySelectorAll(".firstVideo").forEach((vid) => vid.pause());
    },
    captionsToggleOn() {
      // console.log("Captions Toggle");
      const captionSelect = document.querySelector(".full-video-play");
      captionSelect.textTracks[0].mode = "showing"; // "showing" will make them reappear
    },
    captionsToggleOff() {
      // console.log("Captions Toggle");
      const captionSelect = document.querySelector(".full-video-play");
      captionSelect.textTracks[0].mode = "hidden"; // "showing" will make them reappear
    },
  },
};
</script>
